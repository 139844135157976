import React from 'react'

export default function NoPage() {
  return (
    <div style={{height:"50vh",display:"flex",alignItems:"center",justifyContent:"center"}}>
        <h1 style={{textAlign:"center",fontSize:"2rem"}}>
        No Page Found
        </h1>
    </div>
  )
}
