import React from "react";
import Typography from "@mui/material/Typography";
import "../style/home.css";
import logo_img1 from "../assets/images/FIS Race Course SOBO Logo.jpg";
import logo_img2 from "../assets/images/global_logo4.jpg";
import logo_img3 from "../assets/images/EduCluster logo.png";
// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import CallIcon from '@mui/icons-material/Call';
// import LocationOnIcon from '@mui/icons-material/LocationOn';

export default function Footer() {
  return (
    <div className="footer_class">
      <div className="fist_cont">
        <div className="logo1">
          <img src={logo_img1} alt="some new containt" />
        </div>
        <div className="logo2">
          <img src={logo_img2} alt="some new containt" />
        </div>
        <div className="logo3">
          <img src={logo_img3} alt="some new containt" />
        </div>
      </div>
      <div className="sec_cont">
        <div className="new_icon_text_section">
          {/* <LocationOnIcon sx={{margin:"0 15px 0 0"}}/> */}
          <Typography variant="h4">
            <b>
              Finland International School, Race Course<br></br>
            </b>
            Badamia Manor, Street no 11A, <br></br>
            Approach Road, SK Rathod Marg, <br></br>
            Off Keshavrao Khadye Marg, <br></br>
            Opp Income Tax Office, <br></br>
            Mahalaxmi- 400034<br></br>
          </Typography>
        </div>

        <div className="new_icon_text_section">
          {/* <CallIcon sx={{margin:"0 15px 0 0"}}/> */}
          <Typography variant="h4">Email us : info@fis-sobo.com</Typography>
        </div>

        {/* <div className='new_icon_text_section'>
            
            <Typography variant='h4'>
                Mon - Sat : 08.00 AM - 05.30 PM
            </Typography>
            </div> */}
      </div>
    </div>
  );
}
