import React, { useState } from "react";
import Titledata from "./Titledata";
import school_img1 from "../assets/images/adorable-hispanic-girl_img.jpg";
import school_img2 from "../assets/images/book-day-img.jpg";
import school_img3 from "../assets/images/young-childrens-coloring_img.jpg";
import school_img4 from "../assets/images/education_scice_img.jpg";
import { Typography } from "@mui/material";
import star_born_img from "../assets/images/born_star2.jpg";
import Slider from "react-slick";
import activites_img1 from "../assets/images/bandmu_img1.jpg";
import activites_img2 from "../assets/images/art_img1.jpg";
import activites_img3 from "../assets/images/wood_img1.jpg";
import activites_img4 from "../assets/images/dancing_img-1.jpg";
import activites_img5 from "../assets/images/hourse_img1.jpg";
import activites_img6 from "../assets/images/football_img1.jpg";
import activites_img7 from "../assets/images/cricket_img1.jpg";
import extra_new_img1 from "../assets/images/little-boy-IMG1.jpg";
import extra_new_img2 from "../assets/images/schoolchildren-IMG1.jpg";
import extra_new_img3 from "../assets/images/little-kids-playing_IMG.jpg";
import extra_new_img4 from "../assets/images/girl-scientist-with_IMG1.jpg";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export default function Schoolstages() {
  var settings = {
    autoplay: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrow: false,
    responsive: [
      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
    ],
  };
  // eslint-disable-next-line
  const [activies_data, setactivies_data] = useState([
    {
      acti_read: false,
      acti_img: extra_new_img1,
      activ_titles:
        "Play is a key practice in FIS early childhood education and care.",
      activies_data: [
        "Promotes the child’s development, learning, and well-being.",
        "While children learn by playing, they do not personally see play as a vehicle for learning but a way of spending time, living, and perceiving the world instead. ",
        "Combines key elements that promote learning: enthusiasm, doing things together, and challenging personal abilities.",
      ],
    },
    {
      acti_read: false,
      acti_img: extra_new_img2,
      activ_titles: "FIS Race Course introduces five learning areas",
      activies_data: [
        "That describe the key objectives and contents of pedagogical activities in early childhood education and care.",
        "Are combined and applied according to the children’s interests and competence. ",
        "Promote children’s transversal competence.",
      ],
    },
    {
      acti_read: false,
      acti_img: extra_new_img3,
      activ_titles: "Activity based learning",
      activies_data: [
        "Focuses on a child’s natural way of learning things.",
        "Based on stimuli coming through various senses.",
        "Also include tactile learning (based on touch), and kinesthetic learning (based on physical activities).",
      ],
    },
    {
      acti_read: false,
      acti_img: extra_new_img4,
      activ_titles:
        "We do not teach subject related content and academic skills alone. ",
      activies_data: [
        "They are always integrated and mixed together in a meaningful age-appropriate way that promotes learning.",
        "Mathematics and early literature can be learned while playing with sticks and stones.",
        "Using finger paint or drawing to mud can be as effective as paper and pencil.",
        "Who wouldn’t like to learn English through jumping or maths through singing?",
      ],
    },
  ]);

  const readmorefunc = (ind) => {
    const Updateread = [...activies_data];
    Updateread[ind] = {
      ...Updateread[ind],
      acti_read: !Updateread[ind].acti_read,
    };

    // Update the state with the new array
    setactivies_data(Updateread);
  };

  return (
    <div data-aos="fade-up" id="School_Stages_id">
      <Titledata title={"School Stages"} />
      <div className="school_stages_container">
        <div className="sch_images_cont" data-aos="fade-right">
          <div className="school_img">
            <img src={school_img1} alt="some content" />
          </div>
          <div className="imags_text">
            <Typography variant="h3">Pre-primary</Typography>
          </div>
        </div>
        <div className="sch_images_cont" data-aos="fade-left">
          <div className="school_img">
            <img src={school_img2} alt="some content" />
          </div>
          <div className="imags_text">
            <Typography variant="h3">Grade 1-6</Typography>
          </div>
        </div>
        <div className="sch_images_cont" data-aos="fade-right">
          <div className="school_img">
            <img src={school_img3} alt="some content" />
          </div>
          <div className="imags_text">
            <Typography variant="h3">Grade 7-10</Typography>
          </div>
        </div>
        <div className="sch_images_cont" data-aos="fade-left">
          <div className="school_img">
            <img src={school_img4} alt="some content" />
          </div>
          <div className="imags_text">
            <Typography variant="h3">A levels & IBDP</Typography>
          </div>
        </div>
      </div>
      <div className="star_born_cont" data-aos="fade-down">
        <img src={star_born_img} alt="some content" />
        {/* <Button variant='contained' className='butn_lern_more'>learn more</Button> */}
        <Typography variant="h3" className="a_star_text" data-aos="fade-right">
          A star is born.
        </Typography>

        <div className="btn_learn_more_div">
          <a href="#banner_id" className="butn_lern_more" data-aos="fade-up">
            learn more
          </a>
        </div>
      </div>

      <div className="Activites_slider_main" data-aos="fade-up">
        <div className="slider_activies_1">
          <Slider {...settings}>
            <div className="images_activi_cont">
              <img src={activites_img1} alt="some silder content" />
              <div className="activies_title_bg">
                <Typography variant="h3">Band Music</Typography>
              </div>
            </div>
            <div className="images_activi_cont" >
              <img src={activites_img2} alt="some silder content" />
              <div className="activies_title_bg">
                <Typography variant="h3">Art & Design</Typography>
              </div>
            </div>
            <div className="images_activi_cont">
              <img src={activites_img3} alt="some silder content" />
              <div className="activies_title_bg">
                <Typography variant="h3">Wood Work</Typography>
              </div>
            </div>
            <div className="images_activi_cont">
              <img src={activites_img4} alt="some silder content" />
              <div className="activies_title_bg">
                <Typography variant="h3">Singing and Dancing</Typography>
              </div>
            </div>
            <div className="images_activi_cont">
              <img src={activites_img5} alt="some silder content" />
              <div className="activies_title_bg">
                <Typography variant="h3">Horse riding</Typography>
              </div>
            </div>
            <div className="images_activi_cont">
              <img src={activites_img6} alt="some silder content" />
              <div className="activies_title_bg">
                <Typography variant="h3">Football</Typography>
              </div>
            </div>
            <div className="images_activi_cont">
              <img src={activites_img7} alt="some silder content" />
              <div className="activies_title_bg">
                <Typography variant="h3">Cricket</Typography>
              </div>
            </div>
          </Slider>
        </div>
      </div>

      <div className="extra_activities_main" data-aos="fade-down">
        {activies_data?.map((activi, ind) => (
          <>
            <div className="image_activities_container" data-aos="fade-right">
              <img src={activi?.acti_img} alt="some new activies" />
              <div className={`extra_text_container extra_posi${ind + 1}`}>
                <div className="extra_tit">
                  <Typography variant="h3">{activi?.activ_titles}</Typography>
                </div>
                <div className="point_extra_conte">
                  <Typography
                    variant="h4"
                    className={`pgraph_cont ${
                      activi?.acti_read ? "add_height" : ""
                    }`}
                  >
                    <ul>
                      {activi?.activies_data?.map((p_graph) => (
                        <li>{p_graph}</li>
                      ))}
                    </ul>
                  </Typography>
                  <Typography variant="h6">
                    <div className="read_sch" onClick={() => readmorefunc(ind)}>
                      {` ${activi?.acti_read ? "Read less" : "Read more"}`}{" "}
                      <ChevronRightIcon className="arrow_svg" />
                    </div>
                  </Typography>
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  );
}
