// import { useEffect } from "react";
import "./App.css";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import NoPage from "./pages/NoPage";
import ThankYou from "./pages/ThankYou";
import Home from "./pages/home";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <div className="app_finland_sobo">
      <Navbar />
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="thank-you" element={<ThankYou />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
        </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
